import { BdsButton, BdsGrid, BdsImage } from 'blip-ds/dist/blip-ds-react';
import { GetAccountSettings } from 'configurations/Account';
import { useAuth } from 'oidc-react';
import defaultLogoBlipGo from '../../../../assets/LogoBlipGo.png';

export const FinishingInstallation = () => {
  const auth = useAuth();
  const token = GetAccountSettings(auth).token;
  const className = 'FinishingInstallation';

  const startInstallation = async () => {
    // To Do: Code changed to start the development of the next OS development card.
    // This code was used for testing and reuse.
    /* const methodName = 'startInstallation';
    try {
      const data = await installPack('CompanyName', 'email@emailaccountBlip.com', token);
      return data;
    } catch (error: any) {
      Logger.Error(formatLogMessage(`Failed to install Pack: ${error}.`), {
        methodName,
        className,
      });
    } */
  };

  return (
    <div>
      <BdsGrid
        margin="t-7"
        justifyContent="center"
        style={{ left: '18.875rem', position: 'relative', width: '10.25rem', height: '2.5rem' }}
        data-testid="blipGo-logo"
      >
        <BdsImage src={defaultLogoBlipGo} />
      </BdsGrid>
      <BdsGrid margin="t-4" direction="row-reverse" justifyContent="space-between">
        <BdsButton onClick={startInstallation}>Instalar</BdsButton>
      </BdsGrid>
    </div>
  );
};
