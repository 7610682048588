import { YUP_MESSAGE_VALIDATIONS } from 'constants/YupMessageValidations';
import * as Yup from 'yup';

export const businessInformartionOnboardingSchema = Yup.object().shape({
  companyName: Yup.string()
    .required(YUP_MESSAGE_VALIDATIONS.MIXED.REQUIRED)
    .min(3, YUP_MESSAGE_VALIDATIONS.STRING.MIN)
    .max(100, YUP_MESSAGE_VALIDATIONS.STRING.MAX)
    .default(''),
  companySite: Yup.string()
    .required(YUP_MESSAGE_VALIDATIONS.MIXED.REQUIRED)
    .url(YUP_MESSAGE_VALIDATIONS.STRING.URL)
    .max(120, YUP_MESSAGE_VALIDATIONS.STRING.MAX)
    .default(''),
  companyPosition: Yup.string()
    .required(YUP_MESSAGE_VALIDATIONS.MIXED.REQUIRED)
    .max(50, YUP_MESSAGE_VALIDATIONS.STRING.MAX)
    .default(''),
});

export const companyObjectivesOnboardingSchema = Yup.object().shape({
  objectives: Yup.array()
    .of(
      Yup.string().default(''), // Valores permitidos
    )
    .length(4, 'O array de objetivos deve ter exatamente 4 posições') // O array precisa ter exatamente 4 posições
    .default(Array(4).fill('')), // Inicializa o array com 4 strings vazias

  textOtherObjective: Yup.string()
    .test('is-required', 'Este campo é obrigatório se não marcar nenhuma opção acima', function (value) {
      const { objectives } = this.parent; // Acessa o campo "objectives" do contexto atual
      // Verifica se todos os itens no array "objectives" estão vazios
      const allObjectivesEmpty = objectives.every((objective: string) => objective === '');
      // Se todos os checkboxes estiverem desmarcados e o campo de texto estiver vazio, gera erro
      if (allObjectivesEmpty && (!value || value.trim() === '')) {
        return this.createError({ message: 'Este campo é obrigatório se não marcar nenhuma opção acima' });
      }
      return true; // Caso contrário, o campo de texto é válido
    })
    .default(''),
});

export const personalizeServiceOnboardingSchema = Yup.object().shape({
  selectedFeatures: Yup.array(Yup.string().default('')).default([]),
  attendants: Yup.object()
    .shape({
      fourToSeven: Yup.boolean().default(false),
      moreThanSeven: Yup.boolean().default(false),
      onlyOne: Yup.boolean().default(false),
      twoToFour: Yup.boolean().default(false),
    })
    .test('at-least-one-true', 'Pelo menos uma opção de atendentes deve ser selecionada', attendants => {
      // Verifica se pelo menos um dos campos booleanos é true
      return Object.values(attendants).some(value => value === true);
    }),
});
