import styled, { css } from 'styled-components';

interface StepperItemProps {
  $isActive: boolean;
}
export const StepperContent = styled.div`
  display: flex;
  width: 2.5rem;
  margin-top: -1.875rem;
  gap: 0.5rem;
`;

export const StepperItem = styled.div<StepperItemProps>`
  & {
    list-style-type: none;
    float: left;
    position: relative;
    text-align: center;
    margin: 0.1875rem;
  }

  &:before {
    content: ' ';
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    display: block;
    text-align: center;
    margin: 0 auto 0.625rem;
    ${props =>
      props.$isActive
        ? css`
            background-color: #1e6bf1;
          `
        : css`
            background-color: #cfcfcf;
          `}
  }

  &first-child:after {
    content: none;
  }
  &active {
    color: dodgerblue;
  }
  &active:before {
    border-color: dodgerblue;
    background-color: dodgerblue;
  }
`;

export const BorderColorWhenSelected = styled.div<{ $isChecked: boolean }>`
  height: 3.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: ${({ $isChecked }) => ($isChecked ? '0.125rem solid #1e6bf1' : '0.125rem solid #E0E0E0')};
  cursor: pointer;
`;

export const ClickableCheckBox = styled.label`
  display: inline-block;
  padding: 0.5em;
  width: 100%;
`;
