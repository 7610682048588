import { GetAccountSettings } from 'configurations/Account';
import { ApplicationDetail, BlipChatPrefix, BlipGoId } from 'configurations/Environment';
import ChannelsName from 'constants/ChannelsName';
import UpdateContactHubspot from 'constants/ContactStatusHubspot';
import { IsProduction } from 'constants/Environments';
import ExternalUrls from 'constants/ExternalUrls';
import { Modules } from 'constants/Modules';
import { PLAN } from 'constants/PlanType';
import { BLIP_USER } from 'constants/Profiles';
import { RESOURCES } from 'constants/ResourcesNames';
import { Analytics, Logger } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { Installation } from 'objects/types/Installation';
import { Module } from 'objects/types/Module';
import { Resource } from 'objects/types/commands/Resource';
import { ResourceAutomaticResponse } from 'objects/types/resources/ResourceAutomaticResponse';
import { Resources } from 'objects/types/resources/Resources';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addAccount } from 'redux/slices/AccountSlice/accountSlice';
import { updateChannels, useChannelsModule } from 'redux/slices/ChannelsModuleSlice/channelsModuleSlice';
import { fetchBlipChatConfigKey } from 'redux/slices/ChannelsModuleSlice/thunkMiddleware';
import { updateSignedContract } from 'redux/slices/ContractInfoSlice/contractInfoSlice';
import { fetchAllAttendants, fetchAllQueues, fetchAllRules } from 'redux/slices/CustomerServiceSlice/thunkMiddleware';
import { useHubspot } from 'redux/slices/HubspotSlice/HubspotSlice';
import {
  fetchIsInstagramActive,
  fetchIsMessengerActive,
  fetchIsProPlanRequested,
  updateContactPropertiesHubspot,
} from 'redux/slices/HubspotSlice/thunkMiddleware';
import { updateInstallation, useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { addMembers } from 'redux/slices/MembersSlice/membersSlice';
import {
  addActiveModule,
  addLoggedUser,
  updateInstagramActive,
  updateMessengerActive,
  updatePlanType,
  updateWhatsAppActive,
} from 'redux/slices/ProjectSlice/projectSlice';
import { updateResources } from 'redux/slices/ResourceSlices/resourceSlice';
import { AppDispatch } from 'redux/store';
import { delegateBot, directionLeadFindByInstallationId } from 'services/BlipGoApiService';
import { mountUpdateContactHubspotPayload } from 'services/HubspotService';
import {
  GetChannelsActivate,
  getInstallationByInstallationId,
  getInstallationId,
  getInstallationResources,
  getProfilesByInstallation,
} from 'services/PackService';
import { createToastError } from 'services/Toats';
import { isInstagramChannelActive, isMessengerChannelActive, isWhatsAppChannelActive } from 'utils/ActivateChannel';
import { GetBotAuthorization } from 'utils/Installation';
import { formatLogMessage } from 'utils/LogMessage';

const useMainRoute = () => {
  const [isApplicationReady, setIsApplicationReady] = useState<boolean>(false);
  const auth = useAuth();
  const locationSearch = new URLSearchParams(window.location.search.toLowerCase());
  const packId = locationSearch.get('packid') ?? BlipGoId;
  const intentPlanType = locationSearch.get('plan_type');
  const { tenant, router, desk } = useSelector(useInstallation);
  const { channels } = useSelector(useChannelsModule);
  const { isProPlanRequested, isInstagramActive, isMessengerActive } = useSelector(useHubspot);
  const { pathname } = useLocation();
  const screenName = pathname.split('/').pop();
  const className = 'useMainRoute';

  const dispatch = useDispatch<AppDispatch>();

  const token = GetAccountSettings(auth).token;

  useEffect(() => {
    if (!localStorage.getItem('intentPlanType') && intentPlanType) {
      setFirstAccess('true');
      localStorage.setItem('intentPlanType', intentPlanType);
    }
    if (isMessengerActive) {
      handleInstagramAndMessengerChannelsWithHubspot();
    }
  }, [isMessengerActive]);

  useEffect(() => {
    if (router.shortName && tenant.id) {
      handleChannels();
      handleHubspotService();
      handleCustomerService();
      handleChannelsActivate();
    }
  }, [router, tenant]);

  const initHook = async () => {
    const hasInstallationInfo = await handleInstallation();
    let defaultModule = Modules.desk;
    if (hasInstallationInfo) {
      defaultModule = Modules.home;
    }
    handleAccount();
    handleLoggedUser();
    handleActiveModuleDefault(defaultModule);
  };

  const handleAccount = () => {
    dispatch(addAccount(GetAccountSettings(auth)));
  };

  const isIntentProPlan = () => {
    return (
      localStorage.getItem('intentPlanType')?.toLocaleLowerCase() === PLAN.pro &&
      localStorage.getItem('firstAccess')?.toLocaleLowerCase() === 'true'
    );
  };

  const setFirstAccess = (status: string) => {
    localStorage.setItem('firstAccess', status);
  };

  const handlePlanType = async (installationId: string) => {
    const methodName = 'handlePlanType';
    const leadInfo = await directionLeadFindByInstallationId(installationId);
    if (leadInfo !== undefined) {
      dispatch(updatePlanType(leadInfo.planType));
      const signedContract = leadInfo.planType == PlanTypeEnum.Basic || leadInfo.planType == PlanTypeEnum.Pro;
      dispatch(updateSignedContract(signedContract));
    } else {
      createToastError('Falha ao obter as informações sobre plano', 'Por favor, tente novamente daqui alguns minutos.');
      Logger.Error(formatLogMessage('Failed to get plan type'), {
        methodName,
        className,
      });
    }
  };

  const handleLoggedUser = () => {
    const fullName = GetAccountSettings(auth).profile.FullName ?? '';

    dispatch(addLoggedUser(fullName));
  };

  const handleActiveModuleDefault = (defaultModule: Module) => {
    dispatch(addActiveModule(defaultModule));
  };

  const handleInstallation = async () => {
    if (!auth.userData?.profile?.email) return location.reload();

    const methodName = 'handleInstallation';
    try {
      let installationId = '';
      const installationIdParam = locationSearch.get('installationid');
      if (installationIdParam && auth.userData.profile.email.includes(BLIP_USER)) {
        installationId = installationIdParam;
      } else {
        const id = await getInstallationId(token);
        if (!id) {
          setIsApplicationReady(true);
          return false;
        }
        installationId = id.toString();
      }
      const installationDetails = await getInstallationByInstallationId(packId, installationId, token);
      const profiles = await getProfilesByInstallation(installationId, token);
      await handlePlanType(installationId);
      await getResourcesLoadApplication(installationDetails);
      await delegateRouterAndDeskBots(installationDetails);
      dispatch(updateInstallation(installationDetails));
      dispatch(addMembers(profiles));
      return true;
    } catch (ex) {
      createToastError('Falha ao obter as informações', 'Por favor, tente novamente daqui alguns minutos.');
      Logger.Error(formatLogMessage(ex, `Failed to retrieve the Pack: ${packId}.`), {
        methodName,
        className,
      });
    } finally {
      setIsApplicationReady(true);
    }
  };

  const delegateRouterAndDeskBots = async (installation: Installation) => {
    try {
      await Promise.all([
        delegateBot(installation.routerShortName, installation.routerAccessKey),
        delegateBot(installation.deskShortName, installation.deskAccessKey),
      ]);
    } catch (error) {
      if (IsProduction()) {
        createToastError('Falha ao autorizar o chatbot', 'Por favor, tente novamente daqui alguns minutos.');
        Logger.Error('Failed to delegate bots:' + formatLogMessage(error), {
          className,
          methodName: 'delegateRouterAndDeskBots',
        });
      }
    }
  };

  const buildAndReturnAutomaticResponses = (response: Resource[]) => {
    const automaticResponseFormat = /\d\.\s\w+/;

    const automaticResponsesResources = response?.filter((r: Resource) => {
      if (automaticResponseFormat.test(r.name)) {
        return r;
      }
    });
    const automaticResponses: ResourceAutomaticResponse[] = automaticResponsesResources?.map((r: Resource) => {
      return { title: r.name, response: r.value };
    });

    return automaticResponses;
  };

  const buildAndReturnMessages = (response: Resource[]) => {
    return {
      firstContactMessage: response?.find(r => r.name === RESOURCES.MESSAGES.FIRST_CONTACT)?.value ?? '',
      returnMessage: response?.find(r => r.name === RESOURCES.MESSAGES.RETURN)?.value ?? '',
      titleCallUS: response?.find(r => r.name === RESOURCES.MESSAGES.BUTTON_MESSAGE)?.value ?? '',
      preQueueMessage: response?.find(r => r.name === RESOURCES.MESSAGES.PRE_QUEUE)?.value ?? '',
      workdayWithSaturday: response?.find(r => r.name === RESOURCES.MESSAGES.WORKDAY_WITH_SATURDAY)?.value ?? '',
    };
  };

  const buildAndReturnSaturdaySundayAndHoliday = (response: Resource[]) => {
    return {
      saturday: {
        start: response?.find(r => r.name === RESOURCES.DAYS.SATURDAY.START)?.value ?? '',
        end: response?.find(r => r.name === RESOURCES.DAYS.SATURDAY.END)?.value ?? '',
      },
      holiday: {
        start: response?.find(r => r.name === RESOURCES.DAYS.HOLIDAY.START)?.value ?? '',
        end: response?.find(r => r.name === RESOURCES.DAYS.HOLIDAY.END)?.value ?? '',
      },
      sunday: {
        start: response?.find(r => r.name === RESOURCES.DAYS.SUNDAY.START)?.value ?? '',
        end: response?.find(r => r.name === RESOURCES.DAYS.SUNDAY.END)?.value ?? '',
      },
    };
  };

  const getResourcesLoadApplication = async (installation: Installation) => {
    const methodName = 'getResourcesLoadApplication';
    try {
      const response = await getInstallationResources(installation.routerShortName, token);
      const resources: Resources = {
        days: buildAndReturnSaturdaySundayAndHoliday(response),
        messages: buildAndReturnMessages(response),
        holidayAttendance: response?.find(r => r.name === RESOURCES.HOLIDAY_ATTENDANCE)?.value ?? '',
        holidays: response?.find(r => r.name === RESOURCES.HOLIDAYS)?.value ?? '',
        emoji: response?.find(r => r.name === RESOURCES.EMOJI)?.value ?? '',
        openHour: response?.find(r => r.name === RESOURCES.TWENTY_FOUR_HOURS)?.value ?? '',
        workDays: response?.find(r => r.name === RESOURCES.WORK_DAYS)?.value ?? '',
        workSchedule: response?.find(r => r.name === RESOURCES.WORK_SCHEDULE)?.value ?? '',
        preServiceQuestionList: response?.find(r => r.name === RESOURCES.PRE_SERVICE_QUESTIONS)?.value ?? '',
        automaticResponses: buildAndReturnAutomaticResponses(response),
        serviceNote: response?.find(r => r.name === RESOURCES.SATISFACTION_SURVEY)?.value ?? '',
        semFaq: response?.find(r => r.name === RESOURCES.WITHOUT_FAQ)?.value === 'true',
      };

      dispatch(updateResources(resources));
    } catch (ex) {
      createToastError('Falha ao obter os dados do atendimento', 'Por favor, tente novamente mais tarde.');
      Logger.Error(formatLogMessage(ex), {
        methodName,
        className,
      });
    }
  };

  const handleChannels = async () => {
    const methodName = 'handleChannels';

    try {
      mountChannelsLink();

      await dispatch(
        fetchBlipChatConfigKey({
          botShortName: router.shortName,
          testUrl: `https://${tenant.id}.${BlipChatPrefix}?appKey=`,
          token: token,
        }),
      ).unwrap();
    } catch (ex: any) {
      Logger.Error(formatLogMessage(ex), {
        methodName,
        className,
      });
    }
  };

  const mountActivateChannelsUrl = () => {
    const instagramUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/channels/instagram`;
    const messengerUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/channels/messengerDpr`;
    const blipChatUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/channels/blipchat2.0`;

    return { instagramUrl, messengerUrl, blipChatUrl };
  };

  const mountChannelsLink = () => {
    const routerDataLength = Object.keys(router).length;

    if (routerDataLength > 0) {
      (async () => {
        const activateChannels = mountActivateChannelsUrl();
        const channelsUrl = {
          [ChannelsName.Blipchat]: activateChannels.blipChatUrl,
          [ChannelsName.Messenger]: activateChannels.messengerUrl,
          [ChannelsName.Instagram]: activateChannels.instagramUrl,
          default: '',
        };

        const newChannels = channels.map(channel => {
          const newChannel = Object.assign({}, channel);

          newChannel.link = channelsUrl[newChannel.title] ?? channelsUrl.default;

          return newChannel;
        });

        dispatch(updateChannels(newChannels));
      })();
    }
  };

  const handleHubspotService = async () => {
    const methodName = 'handleHubspotService';

    try {
      const key = GetBotAuthorization(router.shortName, router.accessKey);

      await Promise.all([
        dispatch(fetchIsProPlanRequested(key)).unwrap(),
        dispatch(fetchIsInstagramActive(key)).unwrap(),
        dispatch(fetchIsMessengerActive(key)).unwrap(),
      ]);
    } catch (ex: any) {
      Logger.Error(formatLogMessage(ex), {
        methodName,
        className,
      });
    }
  };

  const handleInstagramAndMessengerChannelsWithHubspot = async () => {
    const methodName = 'handleInstagramAndMessengerChannelsWithHubspot';

    try {
      if (isProPlanRequested) {
        return;
      }
      if (isInstagramActive || isMessengerActive) {
        await dispatch(
          updateContactPropertiesHubspot(
            mountUpdateContactHubspotPayload(UpdateContactHubspot.BlipGoFreeConnected, token),
          ),
        ).unwrap();
      }
    } catch (ex: any) {
      Logger.Error(formatLogMessage(ex), {
        methodName,
        className,
      });
    }
  };

  const handleCustomerService = async () => {
    const methodName = 'handleCustomerService';

    try {
      const key = GetBotAuthorization(desk.shortName, desk.accessKey);

      await Promise.all([
        dispatch(fetchAllRules(key)).unwrap(),
        dispatch(fetchAllQueues(key)).unwrap(),
        dispatch(fetchAllAttendants(key)).unwrap(),
      ]);
    } catch (ex: any) {
      createToastError('Falha ao obter os dados do atendimento', 'Por favor, tente novamente em outro navegador.');
      Logger.Error(formatLogMessage(ex), {
        methodName,
        className,
      });
    }
  };

  const handleChannelsActivate = async () => {
    const methodName = 'handleChannelsActivate';

    try {
      const response = await GetChannelsActivate(router.shortName, token);

      dispatch(updateWhatsAppActive(isWhatsAppChannelActive(response)));
      dispatch(updateInstagramActive(isInstagramChannelActive(response)));
      dispatch(updateMessengerActive(isMessengerChannelActive(response)));
    } catch (ex) {
      createToastError('Falha ao verificar canais ativos', 'Por favor, tente novamente mais tarde.');
      Logger.Error(formatLogMessage(ex), {
        methodName,
        className,
      });
    }
  };

  const goTeamWhatsapp = () => {
    Analytics.Track(Analytics.events.MANAGEMENT_REQUEST_SUPPORT, { screenName, origin: 'floatingButton' });
    window.open(`${ExternalUrls.whatsappActivationChat}Olá, estou com dúvidas sobre o Blip GO!`, '_blank');
  };

  return { initHook, isApplicationReady, goTeamWhatsapp, isIntentProPlan, setFirstAccess };
};

export default useMainRoute;
