import { BdsGrid, BdsImage } from 'blip-ds/dist/blip-ds-react';
import {
  BusinessInformartionOnboarding,
  CompanyObjectivesOnboarding,
  PersonalizeServiceOnboarding,
} from 'objects/types/OnboardingOptions';
import { useEffect, useState } from 'react';
import defaultLogoBlipGo from '../../assets/LogoBlipGo.png';
import { ONBOARDING_STEPPER } from '../../constants/SelfOnboard';
import { BusinessInformation } from './components/BusinessInformation/BusinessInformation';
import { CompanyObjectives } from './components/CompanyObjectives/CompanyObjectives';
import { FinishingInstallation } from './components/FinishingInstallation/FinishingInstallation';
import { PersonalizeService } from './components/PersonalizeService/PersonalizeService';
import { StepperContent, StepperItem } from './styles';

export const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(ONBOARDING_STEPPER.companyData);

  const [businessInformation, setBusinessInformation] = useState<BusinessInformartionOnboarding>();
  const [companyObjectives, setCompanyObjectives] = useState<CompanyObjectivesOnboarding>();
  const [personalizeService, setPersonalizeService] = useState<PersonalizeServiceOnboarding>();

  useEffect(() => {
    if (activeStep > ONBOARDING_STEPPER.personalizeService) {
      //todo navigate para tela de instalação
    }
  }, [activeStep]);

  const isTabActive = (index: number) => {
    return activeStep === index;
  };

  const handleNextPage = async () => {
    setActiveStep(activeStep + 1);
  };

  const StepContent = () => {
    switch (activeStep) {
      case ONBOARDING_STEPPER.companyData:
        return <BusinessInformation nextPageEvent={handleNextPage} setBusinessInformation={setBusinessInformation} />;
      case ONBOARDING_STEPPER.companyObjectives:
        return <CompanyObjectives nextPageEvent={handleNextPage} setCompanyObjectives={setCompanyObjectives} />;
      case ONBOARDING_STEPPER.personalizeService:
        return <PersonalizeService nextPageEvent={handleNextPage} setPersonalizeService={setPersonalizeService} />;
      default:
        return <FinishingInstallation />;
    }
  };

  return (
    <BdsGrid
      direction="column"
      alignItems="center"
      gap="4"
      padding="t-7"
      style={{ background: 'white', height: '100vh' }}
    >
      <BdsGrid justifyContent="center" data-testid="blipGo-logo">
        <BdsImage src={defaultLogoBlipGo} />
      </BdsGrid>
      <BdsGrid direction="column">
        <BdsGrid>
          <StepContent />
        </BdsGrid>
        <BdsGrid direction="row">
          <StepperContent id="stepper">
            <StepperItem $isActive={isTabActive(ONBOARDING_STEPPER.companyData)} data-testid="company-data" />
            <StepperItem
              $isActive={isTabActive(ONBOARDING_STEPPER.companyObjectives)}
              data-testid="company-objectives"
            />
            <StepperItem
              $isActive={isTabActive(ONBOARDING_STEPPER.personalizeService)}
              data-testid="personalize-service"
            />
          </StepperContent>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
};
