import { yupResolver } from '@hookform/resolvers/yup';
import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { VSelect } from 'components/Form/VSelect';
import { PROCURATOR_POSITION } from 'constants/MetaActivation';
import { Analytics } from 'infra/adapters';
import { BusinessInformartionOnboarding } from 'objects/types/OnboardingOptions';
import { businessInformartionOnboardingSchema } from 'pages/Onboarding/validations/OnboardingValidation';
import { FormProvider, useForm } from 'react-hook-form';
import { VInput } from '../../../../components/Form/VInput';
import { BusinessInformationContainer } from './style';

interface IProps {
  nextPageEvent: () => void;
  setBusinessInformation: (data: BusinessInformartionOnboarding) => void;
}

export const BusinessInformation = ({ nextPageEvent, setBusinessInformation }: IProps) => {
  const methods = useForm<BusinessInformartionOnboarding>({
    resolver: yupResolver(businessInformartionOnboardingSchema),
    defaultValues: {
      companyName: '',
      companyPosition: '',
      companySite: '',
    },
  });

  const { handleSubmit, control } = methods;

  const onSubmit = (data: BusinessInformartionOnboarding) => {
    setBusinessInformation(data);
    Analytics.Track(Analytics.events.SETUP_INSTALLATION_START, {
      company_name: data.companyName,
      website: data.companySite,
      occupation: data.companyPosition,
    });
    nextPageEvent();
  };

  return (
    <BusinessInformationContainer>
      <BdsGrid direction="column" gap="4">
        <FormProvider {...methods}>
          <BdsGrid direction="column">
            <BdsTypo bold="bold" variant="fs-32" tag="h2">
              Nos conte sobre seu negócio
            </BdsTypo>
            <BdsTypo bold="regular" variant="fs-16">
              Vamos lá! Preencha os dados essenciais para configurar o Blip Go.
            </BdsTypo>
          </BdsGrid>

          <BdsGrid direction="column" gap="3">
            <BdsGrid direction="column" gap="1">
              <BdsTypo bold="bold" variant="fs-14">
                Nome da empresa
              </BdsTypo>
              <VInput
                control={control}
                name="companyName"
                icon="company"
                placeholder="Nome fantasia"
                dataTestId="company-name"
              />
            </BdsGrid>
            <BdsGrid direction="column" gap="1">
              <BdsGrid direction="column">
                <BdsTypo bold="bold" variant="fs-14">
                  Site da empresa
                </BdsTypo>
                <BdsTypo variant="fs-12">Caso não tenha site, utilize o link de perfil do instagram</BdsTypo>
              </BdsGrid>
              <VInput
                control={control}
                name="companySite"
                icon="site"
                placeholder="https://meusite.com.br/"
                dataTestId="company-site"
              />
            </BdsGrid>
            <BdsGrid direction="column" gap="1">
              <BdsTypo bold="bold" variant="fs-14">
                Qual seu cargo na empresa?
              </BdsTypo>
              <VSelect
                control={control}
                name="companyPosition"
                options={PROCURATOR_POSITION}
                icon="business"
                placeholder="Selecione uma opção"
                dataTestId="company-positions-select"
                optionsDataTestId="company-positions-select-options"
              />
            </BdsGrid>
          </BdsGrid>
          <BdsGrid margin="t-4" direction="row-reverse" justifyContent="space-between">
            <BdsButton
              onBdsClick={() => {
                handleSubmit(onSubmit)();
              }}
              data-testid="start-configuration"
            >
              Iniciar configuração
            </BdsButton>
          </BdsGrid>
        </FormProvider>
      </BdsGrid>
    </BusinessInformationContainer>
  );
};
